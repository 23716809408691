<template>
  <div class="filter-management">
    <div
      class="filter-management-blocked"
      v-if="savedFilters.length == 0 && filtersFetched"
    >
      <div class="filter-management-blocked-message">
        <h2>No saved filters yet!</h2>
        <p>This page is for managing your saved filters.</p>
        <p>
          Create a new filter from the side filter on the listing table page.
        </p>
      </div>
    </div>

    <div class="filter-management-list">
      <h2>Clientele</h2>
      <ul>
        <li
          v-for="filter in savedFilters"
          :key="filter.id"
          :class="filterItemClasses(filter.id)"
          v-on:click="selectFilter(filter)"
        >
          {{ filter.name }}
        </li>
      </ul>

      <!-- <div class="filter-management-border-right"></div> -->
    </div>

    <div class="filter-management-details">
      <h2>Client Preference</h2>

      <!-- <div class="filter-management-border-right"></div> -->

      <div class="filter-management-details-left">
        <!-- Disabled for now -->
        <SelectInput
          v-show="false"
          ref="countryFilter"
          placeholder="select an island"
          inputLabel="Island"
          inputName="country"
          :options="filters.countries"
          v-model="filterDetails.country"
        />

        <SelectInput
          ref="agencyFilter"
          placeholder="Select Agency"
          inputLabel="Agency"
          inputName="agency"
          :options="filters.agencies"
          v-model="filterDetails.agency"
        />

        <MultiSelectInput
          ref="districtsFilter"
          placeholder="Select Districts"
          inputLabel="Districts"
          inputName="districts"
          :options="filters.districts"
          v-model="filterDetails.districts"
        />

        <MinMaxInput
          ref="priceRangeFilter"
          inputLabel="Price range"
          inputName="priceRange"
          v-model="filterDetails.priceRange"
        />

        <SelectInput
          ref="propertyTypeFilter"
          inputLabel="Property Type"
          inputName="propertyType"
          placeholder="Select Property Type"
          :options="filters.propertyTypes"
          v-model="filterDetails.propertyType"
        />

        <SelectInput
          ref="parcelTypeFilter"
          inputLabel="Rights to Land"
          inputName="parcelType"
          placeholder="Select Rights to Land"
          :options="filters.parcelTypes"
          v-model="filterDetails.parcelType"
        />

        <MinMaxInput
          ref="lotSizeFilter"
          inputLabel="Lot Size (m2)"
          inputName="lotSize"
          v-model="filterDetails.lotSize"
        />

        <div class="filter-management-details-left-bedrooms">
          <label>Bedrooms</label>
          <RadioButton
            ref="bedroomFilter"
            v-for="(val, index) in filters.bedrooms"
            :key="index"
            inputName="bedrooms"
            :inputLabel="val"
            :parentSelectedValue="filterDetails.bedrooms"
            v-model="filterDetails.bedrooms"
          />
        </div>

        <div class="filter-management-details-left-bathrooms">
          <label>Bathrooms</label>
          <RadioButton
            ref="bathroomFilter"
            v-for="(val, index) in filters.bathrooms"
            :key="index"
            inputName="bathrooms"
            :inputLabel="val"
            :parentSelectedValue="filterDetails.bathrooms"
            v-model="filterDetails.bathrooms"
          />
        </div>

        <div class="filter-management-details-left-listed">
          <label>Days listed</label>
          <RadioButton
            ref="listedFilter"
            v-for="(val, index) in filters.daysListed"
            :key="index"
            inputName="Days Listed"
            :inputLabel="val"
            :parentSelectedValue="filterDetails.daysListed"
            v-model="filterDetails.daysListed"
          />
        </div>

        <div class="filter-management-details-group">
          <div class="group-item">
            <label>Reduced price</label>
            <RadioButton
              ref="reducedFilterYes"
              inputName="Reduced"
              inputLabel="Yes"
              :parentSelectedValue="filterDetails.reduced"
              v-model="filterDetails.reduced"
            />
          </div>

          <div class="group-item">
            <label>Increased price</label>
            <RadioButton
              ref="increasedFilterYes"
              inputName="Increased"
              inputLabel="Yes"
              :parentSelectedValue="filterDetails.increased"
              v-model="filterDetails.increased"
            />
          </div>

          <div class="group-item">
            <label>Pool</label>
            <RadioButton
              ref="poolFilter"
              inputName="pool"
              inputLabel="Yes"
              :parentSelectedValue="filterDetails.pool"
              v-model="filterDetails.pool"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="filter-management-details">
      <div class="filter-management-details-right">
        <TextInput
          ref="filterName"
          inputLabel="Client Name"
          inputName="name"
          inputType="text"
          inputClass="dashboard"
          v-model="filterName"
        />

        <SelectInput
          ref="contractFilter"
          placeholder="Select contract"
          inputLabel="Contract"
          inputName="contract"
          :options="filters.contracts"
          v-model="filterDetails.contract"
        />

        <CheckboxButton
          ref="recieveNotification"
          inputName="recieveEmail"
          inputLabel="Receive Market Update Notification"
          v-model="recieveNotification"
        />

        <div class="filter-management-details-info">
          <ul>
            <li v-for="(filter, index) in filterItems" :key="index">
              {{ filter }}
            </li>
          </ul>
        </div>

        <div class="filter-management-details-options">
          <MercalaButton
            v-on:button-event="deleteFilter()"
            label="Delete"
            type="dashboard-cancel"
          />

          <MercalaButton
            v-on:button-event="saveFilter()"
            label="Save"
            type="dashboard-ok"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Helpers from "@/utils/Helpers";
import MultiSelectInput from "@/components/form/MultiSelectInput";
import MercalaButton from "@/components/form/MercalaButton";
import TextInput from "@/components/form/TextInput";
import SelectInput from "@/components/form/SelectInput";
import RadioButton from "@/components/form/RadioButton";
import CheckboxButton from "@/components/form/CheckboxButton";
import MinMaxInput from "@/components/form/MinMaxInput";
import AngelaAPI from "@/utils/AngelaAPI";
import { EventBus } from "@/utils/EventBus.js";
export default {
  name: "FilterManagement",
  components: {
    MercalaButton,
    TextInput,
    SelectInput,
    RadioButton,
    CheckboxButton,
    MultiSelectInput,
    MinMaxInput,
  },
  data() {
    return {
      filtersFetched: false,
      savedFilters: [],
      selectedFilter: "",
      filterItems: [],
      filters: {
        parcelTypes: [],
        propertyTypes: [],
        countries: [],
        districts: [],
        agencies: [],
        prices: [],
        daysListed: [],
        bedrooms: [],
        bathrooms: [],
        contracts: [],
      },
      filterName: "",
      recieveNotification: false,
      filterDetails: {
        country: "",
        districts: "",
        agency: "",
        priceRange: {
          min: "",
          max: "",
        },
        reduced: "",
        increased: "",
        daysListed: "",
        bedrooms: "",
        bathrooms: "",
        pool: "",
        propertyType: "",
        parcelType: "",
        contract: "",
        lotSize: {
          min: "",
          max: "",
        },
      },
    };
  },
  watch: {
    filterDetails: {
      handler(val, oldVal) {
        this.generateFilterItems();
      },
      deep: true,
    },
  },
  methods: {
    selectFilter(filter) {
      this.selectedFilter = filter;

      Helpers.prefillFilterForm(this, this.selectedFilter, this.filterDetails);

      this.recieveNotification = this.selectedFilter.notify_by_email;
      this.$refs.recieveNotification.setValue(
        this.selectedFilter.notify_by_email
      );

      this.filterName = this.selectedFilter.name;
      this.$refs.filterName.setValue(this.selectedFilter.name);
      this.generateFilterItems();
    },
    saveFilter() {
      AngelaAPI.updateFilter(this.selectedFilter.id, {
        name: this.filterName,
        query: Helpers.saveSearchPayloadCreater(this.filterDetails),
        recieveNotification: this.recieveNotification,
      })
        .then((response) => {
          this.getSavedFilters(response.data.data);
          EventBus.$emit("refresh-saved-filters", "");
          EventBus.$emit("show-notification", {
            title: "Success!",
            message: "Filter updated successfuly!",
            success: true,
            milliseconds: 3000,
          });
        })
        .catch((_error) => {
          EventBus.$emit("show-notification", {
            title: "Oh no...",
            message: "Something went wrong, please try again later.",
            success: false,
            milliseconds: 3000,
          });
        });
    },
    deleteFilter() {
      AngelaAPI.deleteFilter(this.selectedFilter.id)
        .then((response) => {
          this.getSavedFilters("");
          EventBus.$emit("refresh-saved-filters", "");
          EventBus.$emit("show-notification", {
            title: "Success!",
            message: "Filter deleted successfuly!",
            success: true,
            milliseconds: 3000,
          });
        })
        .catch((error) => {
          EventBus.$emit("show-notification", {
            title: "Oh no...",
            message: "Something went wrong, please try again later.",
            success: false,
            milliseconds: 3000,
          });
        });
    },
    generateFilterItems() {
      this.filterItems = Helpers.filterTagGenerator(this.filterDetails);
    },
    getSavedFilters(updatedFilter) {
      AngelaAPI.listFilters()
        .then((response) => {
          this.savedFilters = response.data.data;
          this.filtersFetched = true;
          if (updatedFilter !== "") {
            this.selectFilter(updatedFilter);
          } else {
            if (this.savedFilters.length > 0) {
              this.selectFilter(this.savedFilters[0]);
            }
          }
        })
        .catch((error) => {
          // Nothing to handle
        });
    },
    filterItemClasses(id) {
      return {
        "filter-management-list-item-active": id === this.selectedFilter.id,
      };
    },
  },
  mounted() {
    this.getSavedFilters("");
    this.filters.prices = this.$store.getters.getFilterPrices("all prices");
    this.filters.bedrooms = this.$store.getters.getFilterBedrooms;
    this.filters.bathrooms = this.$store.getters.getFilterBathrooms;
    this.filters.daysListed = this.$store.getters.getFilterDaysListed;
    this.filters.countries = this.$store.getters.getFilterCountries;
    this.filters.propertyTypes = this.$store.getters.getFilterPropertyTypes;
    this.filters.parcelTypes = this.$store.getters.getFilterParcelTypes;
    this.filters.contracts = this.$store.getters.getFilterContracts;

    this.$store.getters.getFilterDistricts.then((districts) => {
      this.filters.districts = districts;
    });

    this.$store.getters.getFilterAgencies.then((agencies) => {
      this.filters.agencies = agencies;
    });
  },
};
</script>

<style lang="scss">
@import "../../../styles/components/dashboard/filters/FilterManagement";
</style>
